import styled from '@emotion/styled';
import { useState } from 'react';
import { PortfolioItem } from './PortfolioItem';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-items: center;
  max-width: 900px;
  margin: 2rem auto;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const portfolioItems = [
  {
    title: "Creative Writing & Storytelling",
    description: "AI-powered bedtime story generator that creates personalized tales based on your child's interests and preferences.",
    bgImage: "https://images.unsplash.com/photo-1456513080510-7bf3a84b82f8?auto=format&fit=crop&w=800&q=80"
  },
  {
    title: "Mental Health & Wellness",
    description: "Interactive AI diary that helps track mood patterns and provides personalized mental wellness insights.",
    bgImage: "https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?auto=format&fit=crop&w=800&q=80"
  },
  {
    title: "Language Learning",
    description: "Innovative language learning platform that uses AI to create immersive, contextual learning experiences.",
    bgImage: "https://images.unsplash.com/photo-1546410531-bb4caa6b424d?auto=format&fit=crop&w=800&q=80"
  },
  {
    title: "Educational Gaming",
    description: "Multiplayer math game with competitive leaderboards and adaptive difficulty levels.",
    bgImage: "https://images.unsplash.com/photo-1587620962725-abab7fe55159?auto=format&fit=crop&w=800&q=80"
  },
  {
    title: "AI-Powered Social",
    description: "Revolutionary social network populated with AI personalities that create engaging, meaningful interactions.",
    bgImage: "https://images.unsplash.com/photo-1529156069898-49953e39b3ac?auto=format&fit=crop&w=800&q=80"
  },
  {
    title: "And Many More!",
    description: "Stay tuned for more innovative AI-powered applications coming soon to teeZee Ventures.",
    bgImage: "https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=800&q=80"
  }
];

export const PortfolioGrid = () => {
  const [flippedIndex, setFlippedIndex] = useState<number | null>(null);

  const handleFlip = (index: number) => {
    setFlippedIndex(flippedIndex === index ? null : index);
  };

  return (
    <Grid>
      {portfolioItems.map((item, index) => (
        <PortfolioItem
          key={index}
          title={item.title}
          description={item.description}
          bgImage={item.bgImage}
          isFlipped={flippedIndex === index}
          onClick={() => handleFlip(index)}
        />
      ))}
    </Grid>
  );
};