import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import { PortfolioGrid } from './components/PortfolioGrid';

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  color: #ffffff;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: 0.01em;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.03'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    z-index: 0;
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.95);
  backdrop-filter: blur(8px);
  z-index: 100;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
`;

const Header = styled(motion.header)`
  padding: 1rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 768px) {
    padding: 1.5rem 2rem;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const LogoImage = styled.img`
  height: 30px;
  width: auto;

  @media (min-width: 768px) {
    height: 60px;
  }
`;

const Logo = styled(motion.h1)`
  font-size: 1.8rem;
  margin: 0;
  background: linear-gradient(45deg, #00f2fe 0%, #4facfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: 0.02em;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Tagline = styled(motion.p)`
  font-size: 1rem;
  margin: 0;
  
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 100px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding-top: 140px;
  }
`;

const Section = styled(motion.section)`
  margin: 4rem 0;
`;

const ContactSection = styled(Section)`
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }

  .contact-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 1.1rem;

    svg {
      width: 20px;
      height: 20px;
      fill: currentColor;
    }

    &:hover {
      color: #00f2fe;
    }
  }
`;

const Footer = styled.footer`
  background: rgba(26, 26, 26, 0.95);
  backdrop-filter: blur(8px);
  padding: 2rem;
  margin-top: 4rem;
  text-align: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;

    a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #00f2fe;
      }
    }
  }
`;

function App() {
  return (
    <Container>
      <HeaderWrapper>
        <Header
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <LogoContainer>
            <LogoImage src="/images/logo.png" alt="teeZee Labs Logo" />
            <Logo>teeZee Labs</Logo>
          </LogoContainer>
          <Tagline
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Building the future, one app at a time
          </Tagline>
        </Header>
      </HeaderWrapper>

      <Content>
        <Section
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h2>Vision</h2>
          <p>
            At teeZee Labs, we're reimagining the future of software development
            through innovative AI-powered applications. Our one-person incubator
            model allows for rapid iteration and focused scaling of successful products.
          </p>
        </Section>

        <Section
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.6 }}
        >
          <h2>Portfolio</h2>
          <p>
            Our growing portfolio includes AI-powered applications in:
          </p>
          <PortfolioGrid />
        </Section>

        <Section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.9 }}
        >
          <h2>Approach</h2>
          <p>
            We believe in lean development, rapid iteration, and data-driven
            scaling. By combining technical expertise with business acumen,
            we transform innovative ideas into successful products.
          </p>
        </Section>

        <ContactSection
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.2 }}
        >
          <h2>Contact</h2>
          <div className="contact-details">
            <p>Tom Zacharski</p>
            <a 
              href="mailto:tom@teezeelabs.com" 
              className="contact-item"
            >
              <svg viewBox="0 0 24 24">
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
              tom@teezeelabs.com
            </a>
            <a 
              href="https://www.linkedin.com/in/zacharski" 
              className="contact-item" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <svg viewBox="0 0 24 24">
                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z"/>
              </svg>
              LinkedIn Profile
            </a>
          </div>
        </ContactSection>
      </Content>

      <Footer>
        <div className="footer-content">
          {/* <div className="footer-links">
            <a href="#privacy">Privacy Policy</a>
            <a href="#terms">Terms of Service</a>
            <a href="#cookies">Cookie Policy</a>
          </div> */}
          <div>
            © {new Date().getFullYear()} teeZee Labs. All rights reserved.
          </div>
          <div>
            Building innovative AI solutions in Singapore
          </div>
        </div>
      </Footer>
    </Container>
  );
}

export default App;
