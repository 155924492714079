import { motion } from 'framer-motion';
import styled from '@emotion/styled';

const Card = styled(motion.div)`
  width: 250px;
  height: 250px;
  perspective: 1000px;
  cursor: pointer;
  margin: 1rem;
`;

const CardInner = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
`;

const CardFace = styled.div<{ bgImage?: string; isBack?: boolean; isFlipped?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  border-radius: 12px;
  background: linear-gradient(145deg, #2a2a2a, #333333);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.3);
  transform: ${props => props.isBack ? 'rotateY(180deg)' : 'rotateY(0)'};
  
  ${({ bgImage }) => bgImage && `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(${bgImage});
      background-size: cover;
      background-position: center;
      opacity: 0.15;
      border-radius: 12px;
      z-index: 0;
    }
  `}

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0.01em;
    position: relative;
    z-index: 1;
    opacity: ${props => props.isFlipped ? 0 : 1};
    transition: opacity 0.3s ease;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5;
    font-weight: 400;
    position: relative;
    z-index: 1;
  }
`;

const CardBack = styled(CardFace)`
  background: linear-gradient(145deg, #00f2fe, #4facfe);
  transform: rotateY(180deg);
`;

interface PortfolioItemProps {
  title: string;
  description: string;
  isFlipped: boolean;
  onClick: () => void;
  bgImage: string;
}

export const PortfolioItem: React.FC<PortfolioItemProps> = ({ 
  title, 
  description, 
  isFlipped, 
  onClick, 
  bgImage 
}) => {
  return (
    <Card onClick={onClick}>
      <CardInner
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6 }}
      >
        <CardFace bgImage={bgImage} isFlipped={isFlipped}>
          <h3>{title}</h3>
        </CardFace>
        <CardBack>
          <p>{description}</p>
        </CardBack>
      </CardInner>
    </Card>
  );
};